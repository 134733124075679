import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    getAllNotifications,
    getNotificationDetails,
    useDriverNotificationsState,
    markAsRead,
    useAccountState,
} from '../../../../Context';
import './index.scss';
import Loader from '../../../../Components/Loader';
import { Link } from 'react-router-dom';

const DriverNotificationsDetails = (props) => {
    const notificationId = props && props.match.params.id;
    const {
        notificationsDispatch,
        notifications: { notificationDetails },
    } = useDriverNotificationsState();
    const { AccountsDispatch } = useAccountState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            notificationsDispatch({ type: 'REQUEST_NOTIFICATIONS_DETAILS' });
            if (notificationId) {
                setLoading(true);
                await markAsRead(notificationsDispatch, notificationId);
                await getAllNotifications(notificationsDispatch);
                await getNotificationDetails(notificationsDispatch, notificationId);
                setLoading(false);
            }
            setLoading(false);
        })();
    }, [AccountsDispatch, notificationsDispatch]);

    return (
        <>
            <div className="w-100 mb-3 mt-1 content-section" style={{ minHeight: 'auto' }}>
                <Row className="mx-2">
                    <Col xs={12} className="px-0 mt-1 ">
                        <Link className="btn btn-secondary text-uppercase my-2 my-sm-0" to={'/driver/notifications'}>
                            <i className="fas fa-chevron-left" /> Back to Messages
                        </Link>
                    </Col>
                    <Col xs={12} className="px-0">
                        <div className="d-flex head-div mt-2">
                            <div className="col-8">
                                <div className="card-l">
                                    <span className="sg-card-lt">{'Subject'}</span>
                                </div>
                            </div>
                            <div className="col-4 text-right">
                                <div className="card-r">
                                    <span className="sg-card-rt">{'Recieved'}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="px-0 msg-div">
                        <div className="d-flex mt-1">
                            <div className="col-8">
                                <span className="msg-sub-s bold">{notificationDetails.subject}</span>
                            </div>
                            <div className="col-4 text-right">
                                <span className="">{notificationDetails.createdAt}</span>
                            </div>
                        </div>
                    </Col>
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Message'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <div>
                            <span className="sg">{notificationDetails.content}</span>
                        </div>
                    </div>
                </Row>
            </div>
            {loading && <Loader />}
        </>
    );
};

export default DriverNotificationsDetails;
