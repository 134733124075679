import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Row, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ListingTable from '../../../../Components/ListingTable';
import {
    getAllNotifications,
    getNotificationDetails,
    useDriverNotificationsState,
    markAsRead,
    useAccountState,
    getTripSettings,
} from '../../../../Context';
import './index.scss';
import CustomAccordion from '../../../../Components/CustomAccordion';
import Loader from '../../../../Components/Loader';
import DetailsPageLayout from '../../../../Components/DetailsPageLayout/DetailsPageLayout';

const DriverNotifications = () => {
    const {
        notificationsDispatch,
        notifications: { notifications, pageInfo },
    } = useDriverNotificationsState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const { reset, register } = useForm();
    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getAllNotifications(notificationsDispatch);
            setLoading(false);
        })();
    }, [AccountsDispatch, notificationsDispatch]);

    const renderDate = (item) => {
        return (
            <div
                style={{
                    fontWeight: item.status === 'New' ? 'bold' : 'normal',
                }}
            >
                {item.createdAt}
            </div>
        );
    };
    const renderSubject = (item) => {
        return (
            <div
                style={{
                    fontWeight: item.status === 'New' ? 'bold' : 'normal',
                }}
            >
                {item.subject}
            </div>
        );
    };
    const renderStatus = (item) => {
        return (
            <div
                style={{
                    fontWeight: item.status === 'New' ? 'bold' : 'normal',
                }}
            >
                {item.status}
            </div>
        );
    };

    const handleRowClick = async (item) => {
        try {
            notificationsDispatch({ type: 'REQUEST_NOTIFICATIONS_DETAILS' });
            if (item && item.id) {
                (async () => {
                    setLoading(true);
                    await markAsRead(notificationsDispatch, item.id);
                    await getAllNotifications(notificationsDispatch);
                    let details = await getNotificationDetails(notificationsDispatch, item.id);
                    reset({ ...details });
                    setLoading(false);
                })();
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };
    return (
        <DetailsPageLayout title="Messages" icon="fa fa-envelope" textColor={tripSettings?.textColor}>
            <Card>
                <Card.Header className="d-flex" style={{ backgroundColor: `${tripSettings?.mainColor}` }}>
                    <h3 className="text-white m-0 w-50">{'Messaging Center'}</h3>
                </Card.Header>
                <Card.Body className="light-gray-bg">
                    {/* Message Details */}
                    <CustomAccordion
                        key={1}
                        id={1}
                        title="Message Content"
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                        collapseItem={collapseItem}
                    >
                        <Row>
                            {/* Name */}
                            <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        placeholder="Subject"
                                        {...register('subject')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                    <Form.Label>Date Recieved</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="createdAt"
                                        name="createdAt"
                                        placeholder="Date Received"
                                        {...register('createdAt')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                    <Form.Label>Content</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        id="content"
                                        name="content"
                                        placeholder="Content"
                                        {...register('content')}
                                        // value={notificationDetails.content}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CustomAccordion>
                    <CustomAccordion
                        id={'DriverNotifications'}
                        title="Message Inbox"
                        collapseItem={collapseItem}
                        sectionColor={tripSettings?.sectionColor}
                        handleItemCollapse={handleItemCollapse}
                    >
                        <ListingTable
                            data={notifications}
                            loading={loading}
                            pageInfo={pageInfo}
                            mainColor={tripSettings?.mainColor}
                            defaultSort={{ key: 'createdAt', order: false }}
                            fieldNames={[
                                ['createdAt', 'Date', true, renderDate],
                                ['status', 'Status', false, renderStatus],
                                ['subject', 'Subject', false, renderSubject],
                            ]}
                            onClick={handleRowClick}
                        />
                    </CustomAccordion>
                </Card.Body>
            </Card>
            {loading && <Loader />}
        </DetailsPageLayout>
    );
};

export default DriverNotifications;
